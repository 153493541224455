import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  footer {
    position: relative;
  }

  @media (min-height: 819px) {
    footer {
      position: absolute;
    }
  }

  @media (min-height: 722px) and (max-width: 1257px) {
    footer {
      position: absolute;
    }
  }
`;

export const Titulo = styled.div`
  display: flex;
  align-self: center;
  width: 90%;
  max-width: 1200px;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 2rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }

  h2 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 3rem;

  @media (max-width: 1180px) {
    aside {
      display: none;
    }

    justify-content: center;
  }
`;

export const PostsContent = styled.div`
  max-width: 70rem;
  margin: auto;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1180px) {
    max-width: 100%;
  }
`;

export const Post = styled(Link)`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 27rem;
    height: 17rem;
    border-radius: 1rem;
  }

  div {
    margin-top: 1rem;
    max-width: 27rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-size: 1.7rem;
      text-align: center;
      color: #222222;
    }

    span {
      margin-top: 0.5rem;
      font-size: 1.3rem;
      color: #888888;
    }
  }

  & + li {
    margin-left: 2.4rem;
  }
`;
