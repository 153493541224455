import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  @media (max-height: 997px) and (min-width: 200px) {
    height: 100%;

    footer {
      position: relative;
    }
  }

  @media (min-height: 716px) and (min-width: 1050px) {
    height: 100vh;

    footer {
      position: absolute;
    }
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 8rem;
  margin-bottom: 3rem;

  display: grid;
  grid-template-columns: 6fr 12fr;
  grid-template-rows: 260px 1fr;
  grid-template-areas:
    'endereco contato'
    'social contato';
  grid-gap: 4rem;

  @media (max-width: 1050px) {
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 3fr 5fr;
    grid-template-areas:
      'endereco social'
      'contato contato';
    grid-gap: 0 4rem;
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 3fr;
    grid-template-areas:
      'endereco'
      'social'
      'contato';
  }

  @media (max-width: 570px) {
    grid-template-rows: 4fr 2fr 7fr;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-start;
  height: 7rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 2rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }

  h2 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    text-transform: uppercase;
  }

  @media (max-width: 416px) {
    &#contato-title {
      height: 12rem;
      h1 {
        height: 11rem;
      }
    }
  }
`;

export const Endereco = styled.div`
  grid-area: endereco;
  width: 100%;
  height: fit-content;

  address {
    display: flex;
    flex-direction: row;
    color: #505050;
    display: block;
    font-size: 1.3rem;
    line-height: 2.8rem;

    h2 {
      margin-top: 3rem;
      font-size: 1.5rem;
      line-height: 2.2rem;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
      color: #505050;

      &:hover {
        color: #94ba65;
      }
    }
  }
`;

export const Social = styled.div`
  grid-area: social;
  width: 100%;
  height: fit-content;

  #social-titulo {
    /* align-self: flex-start; */
    margin-top: 0;
    /*
    h1 {
      font-size: 2.4rem;
    } */
  }

  div {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      display: flex;
      width: 60px;
      height: 60px;
      background-color: #94ba65;
      border-radius: 50%;
      align-items: center;
      justify-content: center;

      svg {
        width: 3.5rem;
        height: 3.5rem;
        color: #ffffff;
      }
    }
  }
`;

export const Contato = styled.div`
  grid-area: contato;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;

  form {
    margin-top: 3rem;
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 3fr 1fr;
    grid-template-areas:
      'email phone'
      'name name'
      'mensage mensage'
      '. button';

    div {
      margin-top: 0;
      margin-left: 0;
    }

    #email {
      grid-area: email;
    }

    #phone {
      grid-area: phone;
    }

    #name {
      grid-area: name;
    }

    #content {
      align-items: initial;
      grid-area: mensage;

      textarea {
        height: 100%;
      }
    }

    #button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      grid-area: button;

      button {
        margin-top: 0;
        max-width: 14rem;
        font-size: 1.7rem;
      }
    }

    @media (max-width: 570px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 3fr 1fr;
      grid-template-areas:
        'email'
        'phone'
        'name'
        'mensage'
        'button';
    }
  }
`;
