import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header, { pagesPublic } from '../../components/Header';
import Footer from '../../components/Footer';

import api from '../../services/api';

import {
  Container,
  Content,
  Titulo,
  Materials,
  ListMaterials,
  Endereco,
} from './styles';

interface Params {
  point_id: string;
}

interface Address {
  id: string;
  cep?: number;
  cidade: string;
  bairro: string;
  rua: string;
  numero?: number;
  referencial?: number;
  latitude: number;
  longitude: number;
}

interface Point {
  name: string;
  image_url: string;
  address: Address;
}

interface Material {
  id: string;
  material: {
    id: string;
    name: string;
    image_url: string;
  };
}

const CreatePoint: React.FC = () => {
  const [materials, setMaterals] = useState<Material[]>([]);
  const [point, setPoint] = useState<Point>({
    name: 'Carregando...',
    image_url: 'Carregando...',
    address: {
      id: 'Carregando...',
      cidade: 'Carregando...',
      bairro: 'Carregando...',
      rua: 'Carregando...',
      latitude: 0,
      longitude: 0,
    },
  });

  const { point_id } = useParams<Params>();

  useEffect(() => {
    api
      .get(`points/${point_id}`)
      .then(response => {
        setPoint(response.data);
      })
      .catch(() => {
        setPoint({
          name: 'not-found',
          image_url: 'not-found',
          address: {
            id: 'not-found',
            cidade: 'not-found',
            bairro: 'not-found',
            rua: 'not-found',
            latitude: 0,
            longitude: 0,
          },
        });
      });
  }, [point_id]);

  useEffect(() => {
    api.get(`points/${point_id}/materials`).then(response => {
      setMaterals(response.data);
    });
  }, [point_id]);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="ecopontos" />

      {point.name === 'not-found' ? (
        <Titulo>
          <h1>Conteudo não Encontrado!</h1>
        </Titulo>
      ) : (
        <>
          <Titulo>
            <h1>{point.name}</h1>
          </Titulo>

          <Content>
            <img src={point.image_url} alt="" />
            <Materials>
              <Titulo
                style={{
                  marginTop: '2rem',
                  marginLeft: 0,
                  marginRight: 0,
                  height: '5rem',
                  border: 'none',
                }}
              >
                <h2>Materiais aceitos:</h2>
              </Titulo>

              <span>
                Em alguns pontos são aceitos outros materiais, nos quais não são
                processados por nossa empresa.
              </span>

              <ListMaterials>
                {materials.map(material => (
                  <li key={material.id}>
                    <img
                      src={material.material.image_url}
                      alt={material.material.name}
                    />
                    <span>{material.material.name}</span>
                  </li>
                ))}
              </ListMaterials>
            </Materials>

            <Endereco>
              <Titulo
                style={{
                  marginTop: '2rem',
                  marginLeft: 0,
                  marginRight: 0,
                  height: '5rem',
                  border: 'none',
                }}
              >
                <h2>Endereço:</h2>
              </Titulo>

              <address>
                {point.address.cep && (
                  <>
                    CEP: <span>{point.address.cep}</span>
                    <br />
                  </>
                )}
                Cidade: <span>{point.address.cidade}</span>
                <br />
                Bairro: <span>{point.address.bairro}</span>
                <br />
                Rua: <span>{point.address.rua}</span>
                <br />
                {point.address.numero && (
                  <>
                    Número: <span>{point.address.numero}</span>
                    <br />
                  </>
                )}
                {point.address.referencial && (
                  <>
                    Referencial: <span>{point.address.referencial}</span>
                    <br />
                  </>
                )}
              </address>
            </Endereco>
          </Content>
        </>
      )}

      <Footer />
    </Container>
  );
};

export default CreatePoint;
