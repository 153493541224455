import styled, { css } from 'styled-components';

interface MenuProps {
  isActive?: boolean;
}

interface MenuSection {
  isActive: boolean;
}

export const Container = styled.div`
  height: 9rem;
  left: 0px;
  top: 0px;

  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(50, 50, 50, 0.3);
  border-radius: 0px;

  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  a {
    img {
      height: 8.9rem;
    }
  }

  @media (max-width: 1070px) {
    width: 95%;
  }

  @media (max-width: 1014px) {
    width: 100%;
  }

  @media (max-width: 853px) {
    width: 90%;
    justify-content: space-between;
    a {
      margin: auto 0;
    }
  }
`;

export const MenuSection = styled.div<MenuSection>`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  nav {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  }

  @media (max-width: 853px) {
    nav {
      display: none;
    }

    ${props =>
      props.isActive
        ? css`
            position: absolute;
            top: 0;
            left: 0;

            width: 100vw;
            height: 100vh;

            background-color: #ecf0f1;

            z-index: 10;

            display: flex;
            align-items: center;

            nav {
              display: block;

              ul {
                text-align: center;

                a {
                  transition-duration: 0.5s;
                  font-size: 3rem;
                }
              }
            }
          `
        : ``}
  }
`;

export const MenuToggle = styled.div<MenuSection>`
  @media (max-width: 853px) {
    width: 40px;
    height: 30px;
    cursor: pointer;

    div {
      background-color: #888888;
      height: 5px;
      width: 100%;
      margin: 6px auto;
      border-radius: 10px;

      transition-duration: 0.3s;
    }

    ${props =>
      props.isActive
        ? css`
            position: absolute;
            right: 0;
            top: 25px;
            margin-right: 6rem;

            .one {
              transform: rotate(45deg) translate(7px, 7px);
            }

            .two {
              opacity: 0;
            }

            .three {
              transform: rotate(-45deg) translate(8px, -9px);
            }
          `
        : ``}
  }
`;

export const Menu = styled.ul<MenuProps>`
  height: 100%;
  ${props =>
    props.isActive
      ? css`
          background: #dddddd;
        `
      : ``}

  a {
    position: relative;
    display: flex;
    height: 9rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    text-decoration: none;
    color: #505050;
  }

  &:hover {
    background: #94ba65;
    a {
      color: #ffffff;
    }
  }
`;
