import styled from 'styled-components';

export const Container = styled.footer`
  position: absolute;
  height: 5rem;
  bottom: 0;
  clear: both;
  background-color: #353432;

  width: 100%;
  justify-content: center;
  display: flex;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin: 1rem #fff;
    height: 4rem;
    width: 90%;
    color: #ecf0f1;
    border-top: 0.1rem solid #4e4d4a;

    font-size: 1.3rem;
  }

  @media (max-width: 1145px) {
    p {
      font-size: 1.4rem;
    }
  }
`;
