import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Leaflet from 'leaflet';

import 'leaflet/dist/leaflet.css';

import { useHistory } from 'react-router-dom';
import Header, { pagesPublic } from '../../components/Header';
import Footer from '../../components/Footer';

import {
  Container,
  Map as MapContainer,
  Content,
  Titulo,
  TitleDescription,
  MapContent,
  Materials,
  Input,
  ListMaterials,
  MaterialItem,
} from './styles';
import api from '../../services/api';

interface Material {
  id: string;
  name: string;
  image_url: string;
}

interface Address {
  id: string;
  cep: number;
  cidade: string;
  bairro: string;
  rua: string;
  numero: number;
  referencial: number;
  latitude: number;
  longitude: number;
}

interface Point {
  id: string;
  name: string;
  image_url: string;
  address: Address;
}

const Points: React.FC = () => {
  const [initialPosition, setInitialPosition] = useState({
    lat: -26.2480264,
    lng: -49.3820276,
  });
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [materials, setMaterials] = useState<Material[]>([]);
  const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);
  const [input, setInput] = useState('');
  const [filtredMaterials, setFiltredMaterials] = useState<Material[]>([]);
  const [points, setPoints] = useState<Point[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setInitialPosition({ lat: latitude, lng: longitude });
    });
  }, []);

  useEffect(() => {
    api.get('materials').then(response => {
      setMaterials(response.data);
    });
  }, []);

  useEffect(() => {
    if (input.length === 0) {
      setFiltredMaterials(materials);
    } else {
      setFiltredMaterials(
        materials.filter(material => material.name.startsWith(input)),
      );
    }
  }, [input, materials]);

  useEffect(() => {
    if (selectedMaterials.length === 0) {
      api.get<Point[]>('points').then(response => {
        setPoints(response.data);
      });
    } else {
      api
        .get<Point[]>('points', {
          params: {
            materials_id: selectedMaterials.join(','),
          },
        })
        .then(response => {
          setPoints(response.data);
        })
        .catch(() => {
          setPoints([]);
        });
    }
  }, [selectedMaterials]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.currentTarget.value);
    },
    [],
  );

  const handleClickPoint = useCallback(
    (point_id: string) => {
      history.push(`/ecoponto/${point_id}`);
    },
    [history],
  );

  const handleFormSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
  }, []);

  const handleSelectedMaterial = useCallback((id: string) => {
    setSelectedMaterials(oldSelectedMaterials => {
      if (oldSelectedMaterials.includes(id)) {
        return oldSelectedMaterials.filter(idSelected => idSelected !== id);
      }
      return [...oldSelectedMaterials, id];
    });
  }, []);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="ecopontos" />

      <TitleDescription>
        <Titulo>
          <h1>
            Pontos de Coletas <span>(Ecopontos)</span>
          </h1>
        </Titulo>

        <p>
          O SAMAE (São Bento do Sul) e a Prefeitura, tomaram a iniciativa de
          distribuir em locais estratégicos da cidade, pontos onde as pessoas
          possam levar seus resíduos, os Eco Pontos. Além dos ecopontos
          espalhados pela cidade de São Bento do Sul. Existem também lojas
          parceiras habilitadas a recolher certos materiais. Materiais que não
          sejam possíveis destinar nos pontos de coletas, seja pelo tamanho ou
          peso, podem ser destinados diretamente em nossa sede, ou agende uma
          coleta.
        </p>
      </TitleDescription>

      <Content>
        <MapContent>
          <Titulo style={{ marginTop: '2rem', height: '5rem', border: 'none' }}>
            <h2>Encontre o mais próximo</h2>
          </Titulo>

          <div>
            <span>Click em um ecoponto para mais informações</span>
          </div>

          <MapContainer>
            <Map
              center={initialPosition}
              zoom={14}
              style={{ width: '100%', height: '100%' }}
              // onclick={params => {
              //   console.log(params.latlng.lat, params.latlng.lng);
              // }}
            >
              <TileLayer
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
              />
              {points.map(point => (
                <>
                  <Marker
                    key={point.id}
                    icon={Leaflet.icon({
                      iconUrl: point.image_url,
                      iconSize: [64, 48],
                    })}
                    position={[point.address.latitude, point.address.longitude]}
                    onclick={() => {
                      handleClickPoint(point.id);
                    }}
                  />
                </>
              ))}
            </Map>
          </MapContainer>
        </MapContent>

        <Materials>
          <Titulo style={{ marginTop: '2rem', height: '5rem', border: 'none' }}>
            <h2>Busque por materiais aceitos</h2>
          </Titulo>

          <form onSubmit={handleFormSubmit}>
            <Input
              isFilled={isFilled}
              isFocused={isFocused}
              data-testid="input-container"
            >
              <input
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={input}
                onChange={handleInputChange}
                ref={inputRef}
                placeholder="BUSCAR POR MATERIAIS"
              />
            </Input>
          </form>

          <ListMaterials>
            {filtredMaterials.map(material => (
              <MaterialItem
                key={material.id}
                selected={selectedMaterials.includes(material.id)}
                onClick={() => {
                  handleSelectedMaterial(material.id);
                }}
              >
                <img src={material.image_url} alt={material.name} />
                <span>{material.name}</span>
              </MaterialItem>
            ))}
          </ListMaterials>
        </Materials>
      </Content>

      <Footer />
    </Container>
  );
};

export default Points;
