import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';

import 'react-day-picker/lib/style.css';

import api from '../../services/api';
import Header, { pagesPublic } from '../../components/Header';

import {
  Calendar,
  Container,
  Content,
  LecturesEventsContent,
  Recents,
  Titulo,
  Post,
  RightSide,
} from './styles';
import Footer from '../../components/Footer';

interface MonthAvailabiliryItem {
  day: number;
  available: boolean;
}

interface EventPost {
  id: string;
  post: Post;
  formatedDate: string;
}

interface Post {
  id: string;
  title: string;
  poster_url: string;
  created_at: string;
  formatedDate: string;
}

const LecturesEvents: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMouth] = useState(new Date());
  const [posts, setPosts] = useState<Post[]>([]);
  const [postsAgenda, setPostsAgenda] = useState<Post[]>([]);
  const [monthAvailabiliry, setMonthAvailabiliry] = useState<
    MonthAvailabiliryItem[]
  >([]);

  useEffect(() => {
    api.get<Post[]>('/posts/lectures-events').then(response => {
      const newPosts = response.data.map(post => {
        return {
          ...post,
          formatedDate: format(parseISO(post.created_at), 'dd/MM/yyyy'),
        } as Post;
      });

      setPosts(newPosts);
    });
  }, []);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMouth(month);
  }, []);

  useEffect(() => {
    api
      .get('/events/month-availability', {
        params: {
          year: currentMonth.getFullYear(),
          month: currentMonth.getMonth() + 1,
        },
      })
      .then(response => {
        setMonthAvailabiliry(response.data);
      })
      .catch(() => {
        setMonthAvailabiliry([]);
      });
  }, [currentMonth]);

  useEffect(() => {
    api
      .get<EventPost[]>('/events/schedule', {
        params: {
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1,
          day: selectedDate.getDate(),
        },
      })
      .then(response => {
        const postsFormatted = response.data.map(eventPost => {
          return {
            ...eventPost.post,
            formatedDate: format(
              parseISO(eventPost.post.created_at),
              'dd/MM/yyyy',
            ),
          } as Post;
        });
        setPostsAgenda(postsFormatted);
      })
      .catch(() => {
        setPostsAgenda([]);
      });
  }, [selectedDate]);

  const selectedDateAsText = useMemo(() => {
    return format(selectedDate, "'Dia' dd 'de' MMMM", {
      locale: ptBR,
    });
  }, [selectedDate]);

  const disableDays = useMemo(() => {
    const dates = monthAvailabiliry
      .filter(monthDay => monthDay.available)
      .map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();

        return new Date(year, month, monthDay.day);
      });

    return dates;
  }, [currentMonth, monthAvailabiliry]);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="palestras-eventos" />
      <Content>
        <RightSide>
          <LecturesEventsContent>
            <Titulo>
              <h1>Palestras e Eventos</h1>
            </Titulo>
            <p>
              Oferecemos palestras e participamos de eventos e workshop, onde
              expomos uma série de informações, repassando conhecimentos,
              experiências e dicas voltadas à prática de reciclagem, além de
              conteúdos voltados aos cuidados do meio ambiente. Também levamos
              equipamentos para que os participantes conheçam na prática o
              interior deles. Nas empresas, participamos nas semanas de
              prevenção de Acidentes, onde promovemos palestras, gincanas e
              campanhas para arrecadação de materiais recicláveis.
            </p>
          </LecturesEventsContent>
          {posts.length !== 0 && (
            <Recents>
              <div>
                <Titulo
                  style={{ marginTop: '2rem', height: '5rem', border: 'none' }}
                >
                  <h2>Recentes</h2>
                </Titulo>
              </div>
              <ul>
                {posts.map(post => (
                  <Post key={post.id}>
                    <a href={`/post/${post.id}`}>
                      <img src={post.poster_url} alt={post.title} />
                      <div>
                        <strong>{post.title}</strong>
                        <span>{post.formatedDate}</span>
                      </div>
                    </a>
                  </Post>
                ))}
              </ul>
            </Recents>
          )}
        </RightSide>
        <Calendar>
          <Titulo>
            <h1>Próxima Palestra ou Evento </h1>
          </Titulo>
          <DayPicker
            weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
            month={new Date()}
            disabledDays={[{ daysOfWeek: [0] }, ...disableDays]}
            modifiers={{
              available: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
            }}
            showOutsideDays
            selectedDays={selectedDate}
            onMonthChange={handleMonthChange}
            onDayClick={handleDateChange}
            months={[
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ]}
          />
          {postsAgenda.length !== 0 && (
            <>
              <Titulo
                style={{ marginTop: '2rem', height: '5rem', border: 'none' }}
              >
                <h2>{selectedDateAsText}</h2>
              </Titulo>
              <ul>
                {postsAgenda.map(post => (
                  <Post key={post.id} agenda>
                    <a href={`/post/${post.id}`}>
                      <img src={post.poster_url} alt={post.title} />
                      <div>
                        <strong>{post.title}</strong>
                        <span>{post.formatedDate}</span>
                      </div>
                    </a>
                  </Post>
                ))}
              </ul>
            </>
          )}
        </Calendar>
      </Content>
      <Footer />
    </Container>
  );
};

export default LecturesEvents;
