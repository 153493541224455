import styled from 'styled-components';

interface Input {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div`
  footer {
    position: relative;
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 2rem;

  img {
    margin-top: 2rem;
    max-width: 1200px;
    width: 100%;

    border-radius: 1.5rem;
    border: 4px solid #94ba65;
  }
`;

export const Titulo = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-self: center;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }

  h2 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    text-transform: uppercase;
  }
`;

export const Materials = styled.div`
  width: 100%;

  span {
    font-size: 1.2rem;
    font-style: italic;
  }
`;

export const ListMaterials = styled.ul`
  display: flex;

  padding: 0.8rem;
  max-height: 12.5rem;
  height: 100%;
  margin-top: 1rem;
  overflow: auto;
  border-radius: 1rem;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0.3rem;
    width: 7.6rem;
    height: 10rem;
    border: 1px solid #94ba64;
    border-radius: 1rem;

    img {
      margin-top: 0;
      border-radius: 0.6rem;
      border: none;
      width: 6.4rem;
      height: 6.4rem;
    }

    span {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: 1.2rem;
      text-align: center;
      color: #000000;
    }

    & + li {
      margin-left: 2rem;
    }
  }
`;

export const Endereco = styled.div`
  address {
    margin-top: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 2.3rem;
    color: #94ba65;

    span {
      color: #505050;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: 1.3rem;
    }
  }
`;
