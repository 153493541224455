import React, { useCallback, useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Link, useHistory, useParams } from 'react-router-dom';

import { format } from 'date-fns';
import api from '../../services/api';

import Header, { pagesPublic } from '../../components/Header';
import TagsList from '../../components/TagsList';
import Footer from '../../components/Footer';

import 'react-image-gallery/styles/css/image-gallery.css';

import {
  Container,
  Content,
  PostContent,
  GaleryContent,
  Titulo,
  Data,
  Relacionados,
  Post,
} from './styles';

interface Params {
  post_id: string;
}

interface Post {
  id: string;
  title: string;
  content: string;
  created_at: string | number;
  poster_url: string;
}

interface Tag {
  id: string;
  name: string;
}

interface PostImages {
  id: string;
  image_url: string;
}

const Contact: React.FC = () => {
  const [post, setPost] = useState<Post>({
    id: 'id-post',
    title: 'Carregando...',
    content: 'Carregando...',
    created_at: Date.now(),
    poster_url: 'Carregando...',
  });
  const [imagesPost, setImagesPost] = useState<ReactImageGalleryItem[]>();
  const [tags, setTags] = useState<Tag[]>([]);
  const [relatedPosts, setRelatedPosts] = useState<Post[]>([]);

  const { post_id } = useParams<Params>();

  const history = useHistory();

  useEffect(() => {
    api.get<Post>(`posts/${post_id}`).then(response => {
      setPost(response.data);
    });
  }, [post_id]);

  useEffect(() => {
    api.get<PostImages[]>(`post/${post_id}/images`).then(response => {
      if (response.data.length !== 0) {
        const parseredImages = response.data.map<ReactImageGalleryItem>(
          image => ({
            original: image.image_url,
            thumbnail: image.image_url,
            originalAlt: 'imagem do post',
            thumbnailAlt: 'imagem do post',
          }),
        );

        setImagesPost(parseredImages);
      }
    });
  }, [post_id]);

  useEffect(() => {
    api.get<Tag[]>(`/posts/${post_id}/tags`).then(response => {
      setTags(response.data);
    });
  }, [post_id]);

  useEffect(() => {
    if (tags.length !== 0) {
      const tagIds = tags.map(item => item.id);
      const parseredTagIds = tagIds.join(',');

      api
        .get<Post[]>(`posts/related?tags_id=${parseredTagIds}`)
        .then(response => {
          setRelatedPosts(response.data);
        });
    }
  }, [tags]);

  const handleClickButton = useCallback(() => {
    history.push(`/busca-tag?tags_id=${tags.map(tag => tag.id).join(',')}`);
  }, [history, tags]);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="novidades" />

      <Titulo>
        <h1>{post.title}</h1>
      </Titulo>

      <Data>
        <span>{format(new Date(post.created_at), 'dd/MM/yyyy')}</span>
        {tags.map(tag => (
          <Link key={tag.id} to={`/busca-tag?tags_id=${tag.id}`}>
            {tag.name}
          </Link>
        ))}
      </Data>

      <Content>
        <PostContent>
          <img src={post.poster_url} alt="Banner do post" />
          <p>{post.content}</p>
          {imagesPost ? (
            <>
              <Titulo
                style={{
                  width: '100%',
                  maxWidth: 'none',
                  height: '5rem',
                  border: 'none',
                }}
                className="TitleCalendar"
              >
                <h2>Galeria</h2>
              </Titulo>
              <GaleryContent>
                <ImageGallery
                  items={imagesPost}
                  infinite
                  lazyLoad
                  showBullets
                  useTranslate3D
                  showIndex
                  autoPlay
                />
              </GaleryContent>
            </>
          ) : (
            ''
          )}
          {relatedPosts.length === 0 ? (
            ''
          ) : (
            <Relacionados>
              <div>
                <Titulo
                  style={{ marginTop: '2rem', height: '5rem', border: 'none' }}
                >
                  <h2>Relacionados</h2>
                </Titulo>
                <button type="button" onClick={handleClickButton}>
                  ver todos
                </button>
              </div>
              <ul>
                {relatedPosts.map(item => (
                  <Post key={item.id}>
                    <a href={`/post/${item.id}`}>
                      <img src={item.poster_url} alt={item.title} />
                      <div>
                        <strong>{item.title}</strong>
                        <span>
                          {format(new Date(item.created_at), 'dd/MM/yyyy')}
                        </span>
                      </div>
                    </a>
                  </Post>
                ))}
              </ul>
            </Relacionados>
          )}
        </PostContent>

        <TagsList />
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
