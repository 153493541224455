import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';

import { Container, Input, ListTags, TagTitle } from './styles';

interface Tag {
  id: string;
  name: string;
}

const TagsList: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [tags, setTags] = useState<Tag[]>([] as Tag[]);
  const [filtredTags, setFiltredTags] = useState<Tag[]>([] as Tag[]);
  const [input, setInput] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    api.get('tags').then(response => {
      setTags(response.data);
      setFiltredTags(response.data);
    });
  }, []);

  useEffect(() => {
    if (input.length === 0) {
      setFiltredTags(tags);
    } else {
      setFiltredTags(tags.filter(tag => tag.name.startsWith(input)));
    }
  }, [input, tags]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.currentTarget.value);
    },
    [],
  );

  return (
    <Container>
      <TagTitle>
        <h2>TAGS</h2>
      </TagTitle>

      <Input
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid="input-container"
      >
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          placeholder="BUSCAR TAGS"
          value={input}
          onChange={handleInputChange}
        />
      </Input>

      <ListTags>
        <ul>
          {filtredTags.map(tag => (
            <li key={tag.id}>
              <Link to={`/busca-tag?tags_id=${tag.id}`}>{tag.name}</Link>
            </li>
          ))}
        </ul>
      </ListTags>
    </Container>
  );
};

export default TagsList;
