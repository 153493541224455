import styled from 'styled-components';

interface Input {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-height: 922px) and (min-width: 1180px) {
    footer {
      position: relative;
    }
  }

  @media (max-width: 1180px) {
    footer {
      position: relative;
    }
  }
`;

export const Titulo = styled.div`
  display: flex;
  align-self: center;
  width: 90%;
  max-width: 1200px;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 12fr 6fr;
  grid-gap: 3rem;
  margin: 0 auto;
  margin-bottom: 3rem;

  @media (max-width: 1180px) {
    grid-template-columns: 1fr;
  }
`;

export const Posts = styled.div`
  margin-top: 2.8rem;
  background: #dddddd;
  max-width: 77rem;
  max-height: 60rem;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  padding: 1.1rem;
  padding-bottom: 0.5rem;

  h2 {
    color: #94ba65;
  }

  @media (max-width: 1180px) {
    max-width: 100rem;
    margin: 0 auto;
    margin-top: 2.8rem;
  }

  @media (max-width: 890px) {
    max-width: 77rem;
  }

  @media (max-width: 685px) {
    max-width: 60rem;
  }

  @media (max-width: 538px) {
    max-width: 50rem;
  }

  button.active {
    border-color: #94ba65;
  }

  button.image-gallery-thumbnail {
    border-radius: 1rem;
    .image-gallery-thumbnail-inner {
      border-radius: 0.6rem;
      .image-gallery-thumbnail-image {
        border-radius: 0.6rem;
      }
    }
  }

  button.image-gallery-thumbnail:hover {
    border-color: #94ba65;
  }

  .image-gallery-icon:hover {
    color: #94ba65;
  }

  .image-gallery-bullet:hover {
    background: #94ba65;
  }

  .image-gallery-image {
    border-radius: 1rem;
  }

  button.image-gallery-thumbnail {
    max-height: 6rem;
    display: inline-block;
    /* overflow: hidden; */
  }

  button.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    max-height: 5rem;
    display: inline-block;
    overflow: hidden;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: contain;
    max-height: calc(60rem - 80px);
  }

  .image-gallery-slide {
    cursor: pointer;
  }

  .image-gallery-index {
    border-radius: 10px;
  }

  .image-gallery-description {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2.6rem;
  }
`;
