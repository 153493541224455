import React, { useCallback, useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import Header, { pagesPublic } from '../../components/Header';
import TagsList from '../../components/TagsList';
import Footer from '../../components/Footer';

import 'react-image-gallery/styles/css/image-gallery.css';

import { Container, Content, Posts, Titulo } from './styles';

interface Post {
  id: string;
  title: string;
  content: string;
  poster_url: string;
}

const Contact: React.FC = () => {
  const [recentPosts, setRecentPosts] = useState<Post[]>([] as Post[]);
  const [posts, setPosts] = useState<ReactImageGalleryItem[]>(
    [] as ReactImageGalleryItem[],
  );
  const [selectedPost, setSelectedPost] = useState(0);
  const history = useHistory();

  useEffect(() => {
    api
      .get('/posts/recents', {
        params: {
          number_posts: 10,
        },
      })
      .then(response => {
        const recentsPosts = response.data as Post[];
        setRecentPosts(recentsPosts);

        const parseredPosts = recentsPosts.map<ReactImageGalleryItem>(post => {
          return {
            original: post.poster_url,
            thumbnail: post.poster_url,
            originalAlt: post.title,
            thumbnailAlt: post.title,
            originalTitle: post.title,
            thumbnailTitle: post.title,
            description: post.title,
          };
        });

        setPosts(parseredPosts);
      });
  }, []);

  const handleClickPost = useCallback(() => {
    history.push(`/post/${recentPosts[selectedPost].id}`);
  }, [history, recentPosts, selectedPost]);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="novidades" />

      <Titulo>
        <h1>Fique por dentro das ultimas novidades</h1>
      </Titulo>

      <Content>
        {posts.length !== 0 ? (
          <Posts>
            <ImageGallery
              items={posts}
              infinite
              lazyLoad
              showBullets
              useTranslate3D
              showIndex
              autoPlay
              showFullscreenButton={false}
              onSlide={setSelectedPost}
              onClick={handleClickPost}
            />
          </Posts>
        ) : (
          <Posts>
            <h2>Carregando ...</h2>
          </Posts>
        )}

        <TagsList />
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
