import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100vh;
  /* padding-bottom: 5rem; */
  display: flex;
  flex-direction: column;

  @media (max-height: 844px) and (min-width: 1258px) {
    footer {
      position: absolute;
    }
  }

  @media (max-height: 910px) and (min-width: 860px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 828px) and (min-width: 769px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 812px) and (min-width: 669px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 750px) and (min-width: 499px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 678px) and (min-width: 446px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 694px) and (min-width: 383px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 666px) and (min-width: 337px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 656px) and (min-width: 309px) {
    footer {
      position: relative;
    }
  }

  @media (max-height: 670px) and (min-width: 200px) {
    footer {
      position: relative;
    }
  }
`;

export const Titulo = styled.div`
  display: flex;
  align-self: center;
  width: 90%;
  max-width: 1200px;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 120rem;
  max-height: 60rem;
  align-items: center;
  margin: 0 auto;

  span {
    margin: 3rem 0;
    font-size: 1.5rem;
    color: #505050;
  }
`;

export const Posts = styled.div`
  background: #dddddd;
  max-width: 77rem;
  height: fit-content;
  max-height: 100%;
  border-radius: 10px;
  padding: 1.1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;

  button.active {
    border-color: #94ba65;
  }

  button.image-gallery-thumbnail {
    border-radius: 1rem;
    .image-gallery-thumbnail-inner {
      border-radius: 0.6rem;
      .image-gallery-thumbnail-image {
        border-radius: 0.6rem;
      }
    }
  }

  button.image-gallery-thumbnail:hover {
    border-color: #94ba65;
  }

  .image-gallery-icon:hover {
    color: #94ba65;
  }

  .image-gallery-bullet:hover {
    background: #94ba65;
  }

  .image-gallery-slide {
    cursor: pointer;
  }

  .image-gallery-image {
    border-radius: 1rem;
  }

  button.image-gallery-thumbnail {
    max-height: 5.7rem;
    display: inline-block;
    /* overflow: hidden; */
  }

  button.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    max-height: 5rem;
    display: inline-block;
    overflow: hidden;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: contain;
    max-height: calc(50rem - 80px);
  }

  .image-gallery-index {
    border-radius: 10px;
  }

  .image-gallery-description {
    font-size: 2.6rem;
  }

  @media (max-width: 858px) {
    max-width: 60rem;
  }

  @media (max-width: 670px) {
    max-width: 50rem;
  }

  @media (max-width: 554px) {
    max-width: 45rem;
  }

  @media (max-width: 498px) {
    max-width: 40rem;
  }

  @media (max-width: 498px) {
    max-width: 35rem;
  }

  @media (max-width: 382px) {
    max-width: 30rem;
  }

  @media (max-width: 336px) {
    max-width: 28rem;
  }
`;
