import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../assets/logo.svg';
import { Container, Header, Menu, MenuToggle, MenuSection } from './styles';

interface Page {
  url: string;
  name: string;
}

interface HeaderProps {
  pageSelected?: string;
  pages: Page[];
}

export const pagesPublic: Page[] = [
  {
    url: 'novidades',
    name: 'Novidades',
  },
  {
    url: 'palestras-eventos',
    name: 'Palestras e Eventos',
  },
  {
    url: 'eventos-anuais',
    name: 'Eventos Anuais',
  },
  {
    url: 'ecopontos',
    name: 'Pontos de Coleta',
  },
  {
    url: 'contato',
    name: 'Contato',
  },
];

export const pagesPrivate: Page[] = [
  {
    url: 'administracao/posts',
    name: 'Posts',
  },
  {
    url: 'administracao/palestras-eventos',
    name: 'Palestras e Eventos',
  },
  {
    url: 'administracao/ecopontos',
    name: 'Pontos de Coletas',
  },
  {
    url: 'administracao/contatos',
    name: 'Contatos',
  },
];

const Head: React.FC<HeaderProps> = ({ pages, pageSelected }) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'initial';
  }, []);

  const HandleToggle = useCallback(() => {
    document.body.style.overflow = menu ? 'initial' : 'hidden';
    setMenu(!menu);
  }, [menu]);

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={logoImg} alt="SBS Reciclagem Eletrónica" />
        </Link>
        <MenuSection isActive={menu} onClick={HandleToggle}>
          <MenuToggle isActive={menu}>
            <div className="one" />
            <div className="two" />
            <div className="three" />
          </MenuToggle>

          <nav>
            {pages.map(page => (
              <Menu
                key={page.name}
                isActive={pageSelected ? pageSelected === page.url : false}
              >
                <Link to={`/${page.url}`}>{page.name}</Link>
              </Menu>
            ))}
          </nav>
        </MenuSection>
      </Header>
    </Container>
  );
};

export default Head;
