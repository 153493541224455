import React, { useCallback } from 'react';
import { FaRecycle, FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import logoBanner from '../../assets/logoBanner.png';
import eco from '../../assets/tree_planting_120-2px.png';

import Header, { pagesPublic } from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

import {
  Container,
  Banner,
  Content,
  Consciente,
  Somos,
  Social,
} from './styles';

const Main: React.FC = () => {
  const history = useHistory();

  const handleContact = useCallback(() => {
    history.push('/contato');
  }, [history]);
  return (
    <Container>
      <Header pages={pagesPublic} />
      <Banner>
        <div>
          <h1>Reciclagem de Eletrônicos</h1>
          <span>
            Destino correto para equipamentos eletricos e eletrônicos.
          </span>
          <Button onClick={handleContact} icon={FaRecycle}>
            <span>Agendar Coleta</span>
          </Button>
        </div>
        <div>
          <img src={logoBanner} alt="Logo SBS Reciclagem Eletrônica" />
        </div>
      </Banner>
      <Content>
        <Consciente>
          <div>
            <h2>Reciclagem Consciente</h2>
          </div>
          <div>
            <strong>
              A SBS Reciclagem cumpre rigorosamente as leis de proteção
              ambiental.
            </strong>
            <div>
              <img src={eco} alt="Eco sustentabilidade" />
              <p>
                Possuímos licença ambiental IMA - para armazenamento de resíduos
              </p>
            </div>
          </div>
        </Consciente>
        <Somos>
          <div>
            <h2>Quem Somos</h2>
          </div>
          <p>
            Somos uma empresa que visa destinar corretamente os equipamentos,
            sempre se preocupando em dar o melhor destino para os seus
            equipamentos.
          </p>
          <p>
            Todo o processo de reciclagem, desde a coleta até o destino final é
            baseado nas leis ambientais vigentes.
          </p>
        </Somos>
        <Social>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/sbsreciclagemeletronica"
          >
            <FaFacebook />
          </a>

          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.instagram.com/sbsreciclagemeletronicabr/"
          >
            <FaInstagram />
          </a>

          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://wa.me/message/GDC6P4FJFE3MF1"
          >
            <FaWhatsapp />
          </a>
        </Social>
      </Content>
      <Footer />
    </Container>
  );
};

export default Main;
