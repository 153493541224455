import { memo } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  footer {
    position: relative;
  }
`;

export const Titulo = styled.div`
  display: flex;
  align-self: center;
  width: 90%;
  max-width: 1200px;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }

  h2 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    text-transform: uppercase;
  }

  @media (max-width: 441px) {
    height: 12rem;
    h1 {
      height: 11rem;
    }
  }
`;

export const Data = styled.div`
  width: 90%;
  max-width: 1200px;
  align-self: center;
  margin-top: 0.6rem;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  span {
    font-size: 1.3rem;
    color: #888888;
  }

  a {
    text-decoration: none;
    margin-left: 1.3rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: #404040;

    :hover {
      color: #94ba65;
    }
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 3rem;

  @media (max-width: 1180px) {
    aside {
      display: none;
    }

    justify-content: center;
  }
`;

export const PostContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  img {
    width: 73.5rem;
    border-radius: 1rem;
  }

  p {
    max-width: 73rem;
    margin-top: 3.3rem;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #404040;
    text-align: justify;
  }

  @media (max-width: 660px) {
    max-width: 60.5rem;
    img {
      width: 60.5rem;
    }

    p {
      max-width: 60rem;
    }
  }

  @media (max-width: 536px) {
    max-width: 50.5rem;
    img {
      width: 50.5rem;
    }

    p {
      max-width: 50rem;
    }
  }

  @media (max-width: 448px) {
    max-width: 40.5rem;
    img {
      width: 40.5rem;
    }

    p {
      max-width: 40rem;
    }
  }

  @media (max-width: 356px) {
    max-width: 30.5rem;
    img {
      width: 30.5rem;
    }

    p {
      max-width: 30rem;
    }
  }
`;

export const GaleryContent = styled.div`
  margin-top: 2.8rem;
  background: #dddddd;
  max-width: 77rem;
  max-height: 100%;
  height: fit-content;
  border-radius: 10px;
  padding: 1.1rem;
  padding-bottom: 0.5rem;

  button.active {
    border-color: #94ba65;
  }

  button.image-gallery-thumbnail {
    border-radius: 10px;
    .image-gallery-thumbnail-inner {
      border-radius: 10px;
      .image-gallery-thumbnail-image {
        border-radius: 6px;
      }
    }
  }

  button.image-gallery-thumbnail:hover {
    border-color: #94ba65;
  }

  .image-gallery-icon:hover {
    color: #94ba65;
  }

  .image-gallery-bullet:hover {
    background: #94ba65;
  }

  .image-gallery-image {
    border-radius: 1rem;
  }

  .image-gallery-index {
    border-radius: 1rem;
  }

  button.image-gallery-thumbnail {
    max-height: 6rem;
    display: inline-block;
    /* overflow: hidden; */
  }

  button.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    max-height: 5rem;
    display: inline-block;
    overflow: hidden;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: contain;
    max-height: calc(60rem - 80px);
  }

  .image-gallery-description {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2.6rem;
  }

  @media (max-width: 660px) {
    max-width: 60rem;
  }

  @media (max-width: 536px) {
    max-width: 50rem;
  }

  @media (max-width: 448px) {
    max-width: 40rem;
  }

  @media (max-width: 356px) {
    max-width: 30rem;
  }
`;

export const Relacionados = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 9rem;
      height: 2.5rem;
      background: #94ba65;
      border: none;
      border-radius: 0.5rem;
      float: right;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  ul {
    display: flex;
    width: 75rem;

    overflow: auto;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 660px) {
    ul {
      width: 60.5rem;
    }
  }

  @media (max-width: 536px) {
    ul {
      width: 50.5rem;
    }
  }

  @media (max-width: 448px) {
    ul {
      width: 40.5rem;
    }
  }

  @media (max-width: 356px) {
    ul {
      width: 30.5rem;
    }
  }
`;

const PostT = styled.li`
  a {
    img {
      width: 27rem;
      height: 17rem;
      border-radius: 1rem;
    }

    div {
      display: flex;
      flex-direction: column;
      max-width: 27rem;

      strong {
        font-size: 1.7rem;
        text-align: center;
        color: #222222;
      }

      span {
        margin-top: 0.5rem;
        font-size: 1.3rem;
        color: #888888;
      }
    }
  }

  & + li {
    margin-left: 2.4rem;
  }
`;

export const Post = memo(PostT);
