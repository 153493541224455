import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ConteinerPrpps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ConteinerPrpps>`
  background: #DCDED6;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #DCDED6;
  color: #999C9F;

  display: flex;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #f36c6c;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #94ba65;
      border-color: #94ba65;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #94ba65;
    `}

  textarea {
    color: #353432;
    flex: 1;
    background: transparent;
    border: 0;
    resize:vertical;

    &::placeholder {
      color: #999C9F;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }
  span {
    background: #f36c6c;
    color: #000000;
    &::before {
      border-color: #f36c6c transparent;
    }
  }
`;
