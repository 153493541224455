import React from 'react';
import { format } from 'date-fns';
import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <p>© SBS Reciclagem de Eletrônicos {format(new Date(), 'yyyy')}</p>
    </Container>
  );
};

export default Footer;
