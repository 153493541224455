import { shade } from 'polished';
import { memo } from 'react';
import styled, { css } from 'styled-components';

interface PostProps {
  agenda?: boolean;
}

export const Container = styled.div`
  height: 100vh;
  position: relative;

  @media (max-height: 780px) and (min-width: 1180px) {
    height: 100%;

    footer {
      position: relative;
    }
  }

  @media (max-width: 1330px) {
    height: 100%;

    footer {
      position: relative;
    }
  }
`;

export const Content = styled.main`
  display: grid;
  grid-template-columns: 12fr 6fr;
  grid-gap: 3rem;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 3rem;

  @media (max-width: 1330px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media (max-width: 376px) {
    width: 98%;
  }
`;

export const RightSide = styled.section`
  max-width: 73rem;

  @media (max-width: 1330px) {
    max-width: 100%;
  }
`;

export const LecturesEventsContent = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 3.7rem;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const Titulo = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;

  h1 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;
  }

  h2 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    text-transform: uppercase;
  }
`;

export const Recents = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 9rem;
      height: 2.5rem;
      background: #94ba65;
      border: none;
      border-radius: 0.5rem;
      float: right;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  ul {
    display: flex;
    width: 73rem;

    overflow: auto;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 1330px) {
    ul {
      width: calc(90vw - 0.7rem);
    }
  }

  @media (max-width: 376px) {
    ul {
      width: 98vw;
    }
  }
`;

const PostT = styled.li<PostProps>`
  a {
    img {
      border-radius: 1rem;
      ${props =>
        props.agenda
          ? css`
              width: 17rem;
              height: 10rem;
            `
          : css`
              width: 27rem;
              height: 17rem;
            `}
    }

    div {
      display: flex;
      flex-direction: column;
      max-width: 27rem;

      ${props =>
        props.agenda
          ? css`
              max-width: 17rem;

              strong {
                font-size: 1.2rem;
              }

              span {
                font-size: 1rem;
              }
            `
          : css`
              strong {
                font-size: 1.7rem;
              }

              span {
                font-size: 1.3rem;
              }
            `}

      strong {
        text-align: center;
        color: #222222;
      }

      span {
        text-align: center;
        margin-top: 0.5rem;
        color: #888888;
      }
    }
  }

  & + li {
    margin-left: 2.4rem;
  }
`;

export const Post = memo(PostT);

export const Calendar = styled.section`
  margin-left: 4rem;
  width: 43rem;
  min-width: 41rem;

  .DayPicker {
    margin-top: 3.7rem;
    background: #d7d9d1;
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker-Months {
    padding: 16px;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 0;
  }

  .DayPicker-Caption > div {
    font-family: 'Roboto Slab', serif;
    font-size: 2rem;
    text-align: center;
  }

  .DayPicker-Weekday {
    font-size: 1.6rem;
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
    font-size: 1.6rem;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #94ba65;
    border-radius: 10px;
    color: #0b1217;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#94BA65')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #999c9f !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #3498db !important;
    border-radius: 10px;
    color: #dcded6 !important;
  }

  ul {
    display: flex;
    width: 43rem;

    overflow: auto;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 1330px) {
    margin-left: 0;
    margin: 0 auto;
    font-size: 200%;
  }

  @media (max-width: 386px) {
    width: 100%;
    min-width: 0;

    ul {
      width: 100%;
    }
  }
`;
