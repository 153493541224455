import React, { useCallback, useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useHistory } from 'react-router-dom';

import Header, { pagesPublic } from '../../components/Header';
import Footer from '../../components/Footer';

import api from '../../services/api';

import 'react-image-gallery/styles/css/image-gallery.css';

import { Container, Content, Posts, Titulo } from './styles';

interface Post {
  id: string;
  title: string;
  poster_url: string;
}

const Contact: React.FC = () => {
  const [annualPosts, setAnnualPosts] = useState<Post[]>([]);
  const [posts, setPosts] = useState<ReactImageGalleryItem[]>(
    [] as ReactImageGalleryItem[],
  );
  const [selectedPost, setSelectedPost] = useState(0);
  const history = useHistory();

  useEffect(() => {
    api.get<Post[]>('/posts/annual').then(response => {
      const recentsPosts = response.data as Post[];
      setAnnualPosts(recentsPosts);

      const parseredPosts = recentsPosts.map<ReactImageGalleryItem>(post => {
        return {
          original: post.poster_url,
          thumbnail: post.poster_url,
          originalAlt: post.title,
          thumbnailAlt: post.title,
          originalTitle: post.title,
          thumbnailTitle: post.title,
          description: post.title,
        };
      });

      setPosts(parseredPosts);
    });
  }, []);

  const handleClickPost = useCallback(() => {
    history.push(`/post/${annualPosts[selectedPost].id}`);
  }, [annualPosts, history, selectedPost]);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="eventos-anuais" />

      <Titulo>
        <h1>Eventos Especiais</h1>
      </Titulo>

      <Content>
        <span>
          Durante todo o ano ocorrem diversas datas especiais que envolvem o
          meio-ambiente. E não poderia ser diferente, estamos presentes nestas
          datas.
        </span>
        <Posts>
          {posts.length === 0 ? (
            <h1>Carregando ...</h1>
          ) : (
            <ImageGallery
              items={posts}
              infinite
              lazyLoad
              showBullets
              useTranslate3D
              showIndex
              autoPlay
              showFullscreenButton={false}
              onSlide={setSelectedPost}
              onClick={handleClickPost}
            />
          )}
        </Posts>
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
