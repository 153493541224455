import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import api from '../../services/api';

import Header, { pagesPublic } from '../../components/Header';
import TagsList from '../../components/TagsList';
import Footer from '../../components/Footer';

import { Container, Content, PostsContent, Titulo, Post } from './styles';

interface Post {
  id: string;
  title: string;
  poster_url: string;
  created_at: string;
  formatedDate: string;
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const SearchTag: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  const query = useQuery();

  const tagsIds = query.get('tags_id');

  useEffect(() => {
    if (tagsIds) {
      api
        .get<Post[]>(`/posts/tags?tags_id=${tagsIds}`)
        .then(response => {
          const newPosts = response.data.map(post => {
            return {
              ...post,
              formatedDate: format(new Date(post.created_at), 'dd/MM/yyyy'),
            } as Post;
          });

          setPosts(newPosts);
        })
        .catch(() => {
          setPosts([]);
        });
    }
  }, [tagsIds]);

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="novidades" />

      <Titulo>
        <h1>Tag Exemplo</h1>
      </Titulo>

      <Content>
        <PostsContent>
          {posts.map(post => (
            <Post key={post.id} to={`post/${post.id}`}>
              <img src={post.poster_url} alt={post.title} />
              <div>
                <strong>{post.title}</strong>
                <span>{post.formatedDate}</span>
              </div>
            </Post>
          ))}
        </PostsContent>

        <TagsList />
      </Content>
      <Footer />
    </Container>
  );
};

export default SearchTag;
