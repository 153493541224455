import styled from 'styled-components';
import _Banner_ from '../../assets/_Banner_.png';

export const Container = styled.div`
  width: 100%;
  background-color: #353432;

  footer {
    position: relative;
  }
`;

export const Banner = styled.div`
  display: flex;
  max-width: 100vw;
  background: url(${_Banner_}) no-repeat center;
  background-size: cover;

  div {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: 12.2rem auto 17.2rem;

    h1 {
      width: 60%;

      color: #fff;
      font-family: Roboto;
      font-size: 3.6rem;
      line-height: 5.4rem;
    }

    span {
      margin-top: 3rem;
      color: #fff;
      font-size: 1.8rem;
    }

    button {
      margin-top: 2.2rem;
      width: 32.8rem;
      height: 6.8rem;
    }
  }

  div + div {
    margin: auto;

    img {
      width: 36.3rem;
      height: 30rem;
    }
  }

  @media (max-width: 856px) {
    div {
      margin: 10rem 0 10rem;
      width: 100%;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 5rem;
      }

      span {
        text-align: center;
        font-size: 2rem;
      }

      button {
        margin-top: 2.2rem;
        width: 32.8rem;
        height: 6.8rem;
      }
    }

    div + div {
      display: none;
    }
  }

  @media (max-width: 423px) {
    background-position: calc(center - 10%);
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 11fr 10fr 1fr;
  height: 100%;

  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`;

export const Consciente = styled.div`
  padding: 4.8rem;
  padding-left: 12rem;
  background-color: #ffffff;

  div {
    border-bottom: 2px solid #eeeeee;
    display: flex;

    h2 {
      padding-right: 2rem;
      float: left;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      padding-bottom: 0.8rem;
      margin-bottom: 0.9rem;
      font-size: 3rem;
      line-height: 2.6rem;
      color: #94ba65;
      border-bottom: 2px solid #94ba65;
    }
  }

  div + div {
    display: flex;
    flex-direction: column;
    border-bottom: none;
    width: 95%;

    strong {
      margin-top: 2rem;
      font-family: Roboto;
      font-size: 2.6rem;
      line-height: 3rem;
      color: #505050;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;

      img {
        width: 7rem;
        height: 7rem;
      }

      p {
        text-align: center;
        max-width: 28rem;
        font-size: 1.6rem;
      }
    }
  }

  @media (max-width: 834px) {
    padding-left: 4.8rem;
  }
`;

export const Somos = styled.div`
  padding: 4.8rem;

  div {
    border-bottom: 1px solid #4e4d4a;
    display: flex;

    h2 {
      padding-right: 2rem;
      float: left;
      padding-bottom: 0.8rem;
      margin-bottom: 0.9rem;
      font-family: Roboto;
      font-size: 3rem;
      line-height: 2.6rem;
      color: #ecf0f1;
      border-bottom: 2px solid #ecf0f1;
    }
  }

  p {
    width: 95%;
    margin-top: 3rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: #ecf0f1;
  }
`;

export const Social = styled.div`
  /* margin-right: 1rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  a {
    display: flex;
    width: 6rem;
    height: 6rem;
    background-color: #94ba65;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    svg {
      width: 3.5rem;
      height: 3.5rem;

      color: #ffffff;
    }
  }

  @media (max-width: 834px) {
    flex-direction: row;
    margin-bottom: 2rem;

    a {
      width: 7rem;
      height: 7rem;

      svg {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 3rem;
  }
`;
