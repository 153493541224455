import React from 'react';
import { Switch } from 'react-router-dom';
import Main from '../pages/Main';
import Contact from '../pages/Contact';
import News from '../pages/News';
import AnnualEvents from '../pages/AnnualEvents';
import LecturesEvents from '../pages/LecturesEvents';
import Post from '../pages/Post';
import SearchTag from '../pages/SearchTag';
import Points from '../pages/Points';
import Point from '../pages/Point';
import NotFound from '../pages/NotFound';

import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route component={Main} path="/" exact />
      <Route component={News} path="/novidades" />
      <Route component={Post} path="/post/:post_id" />
      <Route component={LecturesEvents} path="/palestras-eventos" />
      <Route component={SearchTag} path="/busca-tag" />
      <Route component={AnnualEvents} path="/eventos-anuais" />
      <Route component={Points} path="/ecopontos" />
      <Route component={Point} path="/ecoponto/:point_id" />
      <Route component={Contact} path="/contato" />
      <Route component={NotFound} path="/" />
    </Switch>
  );
};

export default Routes;
