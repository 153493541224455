import styled, { css } from 'styled-components';

interface Input {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.aside`
  max-width: 37rem;
  width: 100%;

  @media (max-width: 1180px) {
    max-width: 100rem;
    margin: 0 auto;
  }

  @media (max-width: 890px) {
    max-width: 77rem;
  }

  @media (max-width: 685px) {
    max-width: 60rem;
  }

  @media (max-width: 538px) {
    max-width: 50rem;
  }
`;

export const TagTitle = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  margin-top: 2.8rem;
  background: #dddddd;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #94ba65;

  h2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #333333;
  }
`;

export const Input = styled.div<Input>`
  background: #dddddd;
  border-radius: 10px;
  padding: 1.6rem;
  width: 100%;
  margin-top: 2rem;

  border: 2px solid #dcded6;
  color: #999c9f;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 0.8rem;
  }

  ${props =>
    props.isFocused &&
    css`
      color: #94ba65;
      border-color: #94ba65;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #94ba65;
    `}

  input {
    color: #353432;
    flex: 1;
    background: transparent;
    border: 0;

    &::placeholder {
      color: #999c9f;
    }
  }
`;

export const ListTags = styled.div`
  border-radius: 1rem;

  ul {
    border: 2x solid #dddddd;
    padding: 0.8rem;
    max-height: 45rem;
    margin-top: 1rem;
    overflow: auto;
    border-radius: 1rem;

    li {
      & + li {
        margin-top: 2.7rem;
      }

      a {
        font-size: 1.6rem;
        color: #505050;
        text-decoration: none;
        text-transform: capitalize;
      }
    }
  }
`;
