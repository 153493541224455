import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
import {
  FiMail,
  FiPhone,
  FiUser,
  FiMessageSquare,
  FiSend,
} from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

import Header, { pagesPublic } from '../../components/Header';
import Footer from '../../components/Footer';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';

import { Container, Content, Endereco, Contato, Social, Title } from './styles';

interface SignInFormData {
  email: string;
  name: string;
  content: string;
  phone?: string;
}

const Contact: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório.'),
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('Digite um e-mail válido.'),
          content: Yup.string().required('É nessário uma mensagem.'),
          phone: Yup.string().optional(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.phone === '') {
          // eslint-disable-next-line no-param-reassign
          delete data.phone;
        }

        await api.post('contact', data);

        history.push('/');

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description:
            'Sua Mensagem foi enviada com sucesso! Em breve responderemos!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no formulário',
          description: 'Ocorreu um erro ao enviar o formulário',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Header pages={pagesPublic} pageSelected="contato" />
      <Content>
        <Endereco>
          <Title>
            <h1>Endereço</h1>
          </Title>

          <address>
            <h2>SBS Reciclagem</h2>
            Rua Carlos Urban, 690, Schramm
            <br />
            São Bento do Sul - SC
            <br />
            Telefone: (47) 99949-4830
            <br />
            Telefone: (47) 99949-4840
            <br />
            E-mail:{' '}
            <a href="mailto:contato@sbsreciclagemeletronica.com.br">
              contato@sbsreciclagemeletronica.com.br
            </a>
          </address>
        </Endereco>

        <Social>
          <Title id="social-titulo">
            <h2>Siga-nos nas redes sociais</h2>
          </Title>
          <div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/sbsreciclagemeletronica"
            >
              <FaFacebook />
            </a>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/sbsreciclagemeletronicabr/"
            >
              <FaInstagram />
            </a>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://wa.me/message/GDC6P4FJFE3MF1"
            >
              <FaWhatsapp />
            </a>
          </div>
        </Social>

        <Contato>
          <Title id="contato-title">
            <h1>Entre em contato conosco</h1>
          </Title>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              id="email"
              name="email"
              icon={FiMail}
              type="mail"
              placeholder="E-mail"
            />

            <Input id="name" name="name" icon={FiUser} placeholder="Nome" />

            <TextArea
              id="content"
              name="content"
              icon={FiMessageSquare}
              placeholder="Sua Mensagem"
            />

            <Input
              id="phone"
              name="phone"
              icon={FiPhone}
              type="tel"
              placeholder="Telefone (opcional)"
            />

            <div id="button">
              <Button type="submit" icon={FiSend} sizeIcon={22}>
                Enviar
              </Button>
            </div>
          </Form>
        </Contato>
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
