import { memo } from 'react';
import styled, { css } from 'styled-components';

interface Input {
  isFocused: boolean;
  isFilled: boolean;
}

interface MaterialProps {
  selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  @media (max-height: 1092px) and (min-width: 100px) {
    height: 100%;
    footer {
      position: relative;
    }
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 12fr 6fr;
  grid-gap: 3rem;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media (max-width: 1180px) {
    grid-gap: 0;
    grid-template-columns: 1fr;
  }
`;

export const TitleDescription = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  p {
    margin-top: 3rem;

    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #505050;
  }
`;

export const Titulo = styled.div`
  display: flex;
  align-self: center;
  height: 7rem;
  margin-top: 6.2rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #eeeeee;
  align-self: flex-start;

  h1 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    height: 6.4rem;

    span {
      font-size: 2.2rem;
      font-style: italic;
    }
  }

  h2 {
    padding-right: 1rem;
    float: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    color: #94ba65;
    border-bottom: solid 2px #94ba65;
    line-height: 5rem;
    text-transform: uppercase;
  }

  @media (max-width: 471px) {
    height: 12rem;
    h1 {
      height: 11rem;
    }
  }
`;

export const MapContent = styled.main`
  > div {
    margin-top: 1rem;
    span {
      font-size: 1.2rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-style: normal;
    }
  }

  @media (max-width: 1180px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
`;

export const Map = styled.div`
  width: 73rem;
  height: 60rem;

  border: 0.2rem solid #94ba65;

  .leaflet-container {
    z-index: 2;
  }
  .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
    border-radius: 1rem;
    border: 3px solid #94ba65;
    max-width: 20rem;
    background-color: #94ba65;
  }
  @media (max-width: 1180px) {
    width: 100%;
  }
`;

export const Materials = styled.aside`
  max-width: 40rem;
  width: 100%;

  form {
    margin-top: 2rem;
    display: flex;
    button {
      width: 6rem;
      margin-left: -2rem;
      background: #94ba65;
      border: none;
      border-radius: 10px;

      svg {
        color: #ffffff;
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  @media (max-width: 1180px) {
    max-width: 100%;
  }
`;

export const Input = styled.div<Input>`
  background: #dddddd;
  border-radius: 10px;
  padding: 1.6rem;
  width: 100%;

  border: 2px solid #dcded6;
  color: #999c9f;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 0.8rem;
  }

  ${props =>
    props.isFocused &&
    css`
      color: #94ba65;
      border-color: #94ba65;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #94ba65;
    `}

  input {
    color: #353432;
    flex: 1;
    background: transparent;
    border: 0;

    &::placeholder {
      color: #999c9f;
    }
  }
`;

export const ListMaterials = styled.div`
  border-radius: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  grid-gap: 20px;
  border: 2px solid #dddddd;
  padding: 0.8rem;
  max-height: 55rem;
  margin-top: 1rem;
  overflow: auto;
  border-radius: 1rem;
`;

const MaterialItemStyle = styled.button<MaterialProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.3rem;
  width: 7.6rem;
  height: 10rem;
  border: 1px solid #94ba64;
  border-radius: 1rem;

  ${props =>
    props.selected
      ? css`
          border-color: #34cb79;
          border-width: 2px;
        `
      : ``}

  cursor: pointer;

  img {
    border-radius: 0.6rem;
    width: 6.4rem;
    height: 6.4rem;
  }

  span {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.2rem;
    text-align: center;
    color: #000000;
    text-transform: capitalize;
  }
`;

export const MaterialItem = memo(MaterialItemStyle);
